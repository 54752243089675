.search-container {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    max-width: 870px;
    border-radius: 50px;
    border: 0px;
    display: flex;
    padding: 5px 10px;
    justify-content: space-between;
    background-color: #fff;
    margin-top: -46px;
    position: relative;
    z-index: 999;
    box-shadow: 0px 3px 4px 0px #00000033;
}

.search-container input {
    border: 0px;
    width: 100%;
    padding: 13px;
    letter-spacing: 2px;
}

.search-container input:focus {
    outline: 0px;
}

.search-container button {
    border-radius: 50px;
    padding: 15px 60px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

.search-container img {
    padding-left: 20px;
    max-width: 50px;
}

.search-container .search-inner {
    display: flex;
    width: 100%;
}

.search-list {
    position: absolute;
    left: 17.5%;
    margin: auto;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: scroll;
}

.search-list li {
    padding: 10px 0px;
}

@media screen and (max-width:991px) {
    .search-container {
        max-width: 700px;
    }
}

@media screen and (max-width:768px) {
    .search-container {
        max-width: 440px;
        padding: 4px 6px;
        margin-top: -25px;

    }

    .search-container input {
        font-size: 12px;
        padding: 0px 13px;
    }

    .search-container button {
        padding: 10px 17px;
        font-size: 10px;
        font-weight: 400;
    }

    .search-container img {
        max-width: 35px;
    }

    .search-list {
        left: 21%;
        top: 38%;
    }
}

@media screen and (max-width:510px) {
    .search-container {
        max-width: 320px;
        padding: 4px 6px;
    }
    .search-list {
        left: 5%;
        top: 36%;
    }
    .search-list ul li{
        text-align: center;
    }
}