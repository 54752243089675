.section_header {
  padding: 0px;
  background-color: #fff;
}

.section_header .header-logo img {
  max-width: 230px;
}

.section_header .header-menu {
  text-align: center;
}

.section_header .header-menu p {
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #0081C3;
  font-size: 1rem;
}

.section_header .header-menu p a {
  color: #0081C3;
  text-decoration: none;
}

.section_header .header-btn {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}

.section_header .header-btn .btn-primary {
  border-radius: 60px;
  padding: 15px 18px;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 12px;
}

.section_header .main-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.section_header .header-btn .header-call {
  box-shadow: 2px 2px 4px 0px #00000040;
  padding: 10px;
  border-radius: 60px;
}

.section_header .header-btn .header-call img {
  max-width: 20px;
  width: 100%;
}

.section_header hr {
  margin: 0px;
}

.megamenu .nav-link {
  color: #0081C3 !important;
  font-size: 14px !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.megamenu .dropdown-mega {
  position: relative;
  z-index: 9999;
}

.megamenu .megamenu_inner {
  justify-content: center;
}

.rotate-180{
  transform: rotate(180deg);
}

.megamenu .navbar-nav .dropdown {
  position: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0081C3;
}

.megamenu .navbar-nav .nav-item.dropdown:active .dropdown-menu,
.megamenu .navbar-nav .nav-item.dropdown:hover .dropdown-menu {
  display: block;
  display: flex;
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  flex-wrap: wrap;
  border-radius: 0px 0px 6px 6px;
  justify-content: space-between;
}

.megamenu .navbar-nav .nav-item.dropdown:active .dropdown-menu ul,
.megamenu .navbar-nav .nav-item.dropdown:hover .dropdown-menu ul {
  list-style: none;
  padding: 0px;
}

.megamenu {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.megamenu .megamenu_item {
  color: #000000;
  font-size: 16px !important;
  font-weight: 600;
  margin: 0.25rem 0 !important;
}

/* Add a bit of space between dropdown items */
.navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item {
  margin: 0.25rem 0;
  font-size: 15px;
  margin: 0px;
  padding: 0px 15px 0px 15px;
}

.mobile-toggle {
  display: none;
}

@media screen and (max-width:1212px) and (min-width:992px) {
  .section_header .header-btn {
    gap: 9px;
    /* justify-content: space-between; */
  }

  .section_header .header-btn .header-call {
    padding: 7px 10px;
  }

  .section_header .header-btn .btn-primary {
    padding: 9px 11px;
    font-size: 12px;
  }

  .section_header .header-menu p {
    font-size: 17px;
  }

  .megamenu .nav-link {
    font-size: 12px;
    font-weight: 700;
  }

}

.section_banner .carousel-control-prev-icon,
.section_banner .carousel-control-next-icon {
  position: relative;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-image: none;
  border: 1px solid #0081C3;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width:991px) {
  .section_header .header-btn .btn-primary {
    padding: 13px 11px;
    font-size: 12px;
  }

  .section_header .header-menu {
    display: none;
  }

  .mobile-toggle {
    display: block;
    text-align: right;
  }

  .mobile-toggle .btn-primary {
    margin: 0px;
    padding: 0px;
    width: 100%;
    max-width: 50px;
    height: 47px;
    border-radius: 50%;
    background-color: #0081C3;
  }

  .section_header hr {
    display: none;
  }

  .section_header .main-header {
    justify-content: space-between;
    padding-top: 10px;
  }

  .section_header .header-btn {
    gap: 8px;
    padding: 0px;
  }
}

.mobile-menuinner {
  border-top: 2px solid #0081C3;
  margin-top: 14px;
  padding-top: 20px;
}

.mobile-menuinner ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.mobile-menuinner li {
  border-bottom: 1px solid #EAECED;
  padding: 0px 0px 7px 0px;
  margin: 0px 0px 7px 0px;
}

.mobile-menuinner li:last-child {
  border: 0px;
}

.mobile-menuinner li a {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  width: auto;
}


@media screen and (max-width:768px) {

  .section_banner .carousel-control-prev-icon,
  .section_banner .carousel-control-next-icon {
    width: 24px;
    height: 24px;
  }

  .section_header .header-btn .btn {
    display: none;
  }

  .section_header .header-btn .header-call img{
    width: 15px;
    padding-bottom: 2px;
  }

  .section_header .header-btn .header-call {
    padding: 2px 6px;
    border-radius: 100%;
  }

  .mobile-toggle .btn-primary {
    height: 35px;
    max-width: 35px;
  }

  .mobile-toggle .btn-primary img{
    width: 13px;
  }
}

@media screen and (max-width:440px) {
  .section_header .header-btn .btn-primary {
    padding: 9px 6px;
    font-size: 8px;
  }
}