.sticky-search-container {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    max-width: 870px;
    border-radius: 50px;
    border: 0px;
    display: flex;
    padding: 4px 8px;
    justify-content: space-between;
    background-color: #f1f1f1;
    margin-top: -35px;
    position: relative;
    z-index: 1000;
}

.sticky-search-container input {
    border: 0px;
    width: 100%;
    padding: 13px;
    letter-spacing: 2px;
    background-color: #f1f1f1;
}

.sticky-search-container input:focus {
    outline: 0px;
}

.sticky-search-container button {
    border-radius: 50px;
    padding: 15px 60px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

.sticky-search-container img {
    padding-left: 20px;
    max-width: 50px;
}

.sticky-search-container .sticky-search-inner {
    display: flex;
    width: 100%;
}

.sticky-search-list {
    position: absolute;
    left: 25%;
    margin: auto;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: scroll;
    width: 50%;
}

.sticky-search-list li {
    padding: 10px 0px;
}

@media screen and (max-width:991px) {
    .sticky-search-container {
        max-width: 700px;
    }
}

@media screen and (max-width:768px) {
    .sticky-search-container {
        max-width: 440px;
        padding: 4px 6px;
        margin-top: -25px;

    }

    .sticky-search-container input {
        font-size: 12px;
        padding: 0px 13px;
    }

    .sticky-search-container button {
        padding: 10px 17px;
        font-size: 10px;
        font-weight: 400;
    }

    .sticky-search-container img {
        max-width: 35px;
    }
}

@media screen and (max-width:510px) {
    .sticky-search-container {
        max-width: 320px;
        padding: 4px 6px;
    }
}